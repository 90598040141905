<template>
  <div>
    <a-button type="primary" @click="showModal()">
          新增
    </a-button>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
    >
    </a-table>
    <Pagination :current="current" :total="total" @handleChange="handleChange" v-show="total > 20" />
    <a-modal
      :title="title"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
      width="450px"
    >
      <a-form :form="form1" :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }">
          <a-form-item label="软件收费标准">
            <a-input-number
              style="width: 80%"
              :min="1"
              :max="100000000"
              v-decorator="['size', {rules: [{ required: true, message: '请输入软件收费标准!' }] }]"
              placeholder="软件收费标准(MB)"
              autocomplete="off"
            >
            </a-input-number>
          </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { SoftPayModel } from "../../../models/pay_soft";
import Pagination from '../../../components/Pagination'
import formatTime from '../../../utils/formatTime'
const softPayModelModel = new SoftPayModel();
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

const columns = [
  {
    title: "软件收费标准(MB)",
    dataIndex: "size",
    key: "size",
    width: 40,
  },
  {
    title: "操作人",
    dataIndex: "admin_name",
    key: "admin_name",
    width: 50,
  },
  {
    title: "操作时间",
    dataIndex: "created_at",
    key: "created_at",
    width: 80,
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      visible: false,
      loading: false,
      total: 0,
      title: "",
      current: 1,
      // locale,
    };
  },

  created() {
    this.queryList();
  },

  methods: {
    
    handleOk(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
            this.$notification.open({
              message: "提示",
              description: "数据处理中请稍后！",
              duration: 2,
            });
            softPayModelModel.create(values).then((res) => {
                if (res.code == 0) {
                    this.$notification.open({
                        message: "提示",
                        description: res.message,
                        duration: 2,
                    });
                    this.visible = false;
                    this.queryList();
                }
            });
            this.form1.resetFields();
            this.queryList();
        }
      });
    },

    handleCancel() {
      this.form1.resetFields();
    },
   
    showModal() {
      this.visible = true;
      this.title = "新增";
    },
    handleChange(page) {
      this.current = page
      this.queryList(page)
    },
    queryList(page) {
      this.loading = true;
      softPayModelModel.index(page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          this.data = res.result;
          this.loading = false;
          res.result.map((item, index) => {
            console.log(index);
            item.created_at = formatTime(item.created_at)
            return item
          })
          this.total = res.total;
        }
      });
    },
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.sm-select {
  width: 170px;
}
</style>